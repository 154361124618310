<template>
  <div>
    <div
      @click="$emit('click')"
      :class="style"
      class="cta d-flex flex-column border justify-content-center align-items-center"
    >
      <span
        v-if="isLoading"
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
      <fa-icon v-else :icon="['fas', icon]"></fa-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExerciseStateButton',
  props: {
    forState: {
      type: String,
      default: 'completed',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      return this.forState === 'completed' ? 'check' : 'times';
    },
    style() {
      const styles = [];
      styles.push(this.forState === 'completed' ? 'cta-positive' : 'cta-negative');
      if (this.isActive) styles.push('active');
      if (this.isLoading) styles.push('is-busy');
      return styles.join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/vars.scss';

$size: 4rem;

.cta {
  transition: all .2s ease-in-out;
  width: $size;
  height: $size;
  border-width: 2px !important;
  border-radius: calc(#{$size} / 2);

  &.active {
    font-size: 1.75rem;
    color: white;
  }
}

.cta-positive {
  border-color: $primary !important;
  color: $primary;

  &.active {
    background-color: $primary;
  }
}

.cta-negative {
  border-color: $danger !important;
  color: $danger;

  &.active {
    background-color: $danger;
  }
}
</style>
